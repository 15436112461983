import React, {useEffect, useState} from "react";
import ReorderButtons from "./ReorderButtons";
import {FiX} from "react-icons/all";
import DeleteModulePromptModal from "./DeleteModulePromptModal";
import {incrementLoading} from "../redux/meta/MetaActions";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";

interface IReOrderModuleListItemProps {
	dispatch?: any;
	title: string;
	selectedModule: boolean;
	index: number;

	onReOrder?(up: boolean): void;
	onDelete?(): void;
}

const ReOrderModuleListItem: React.FC<IReOrderModuleListItemProps> = (props: IReOrderModuleListItemProps) => {

	const {title, selectedModule, index} = props;
	const [moduleToDelete, setModuleToDelete] = useState<number>(-1);

	function entryReOrderIntercept(up: boolean): () => void {
		return () => {
			props.onReOrder(up);
		}
	}

	function selectModule(e): void {
		e.preventDefault();
		setModuleToDelete(index);
	}

	function cancelDelete(): void {
		setModuleToDelete(-1);
	}

	function deleteHelper(): void {
		props.dispatch(incrementLoading());
		setModuleToDelete(-1);
		setTimeout(() => {
			props.onDelete();
		}, 1000);
	}

	return (
		<React.Fragment>
			<DeleteModulePromptModal
				module={title}
				isOpen={moduleToDelete > -1}
				onCancel={cancelDelete}
				onDelete={deleteHelper}
			/>

			<div className="w-100 d-flex justify-content-between p-2 module-reorder-hover border-bottom">
				<p className="mb-0">
				<span className={selectedModule ? "text-dark" : "text-muted"}>
					{title}
				</span>

					{selectedModule && (
						<span className="ml-3 font-italic text-success">
						(Currently Editing)
					</span>
					)}
				</p>

				<div className="d-flex">
					<ReorderButtons
						upCallback={entryReOrderIntercept(true)}
						downCallback={entryReOrderIntercept(false)}
					/>

					<a href="" onClick={selectModule} className="ml-4 text-danger">
						<FiX size="1.5rem" style={{maxHeight: 40}}/>
					</a>
				</div>
			</div>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IReOrderModuleListItemProps) => {
	return {
		...props,
	}
})(ReOrderModuleListItem);

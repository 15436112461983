import React from "react";
import {PersistGate} from "redux-persist/integration/react";
import {persistor} from "./redux";
import {BrowserRouter, Route, Switch, Redirect, useLocation} from "react-router-dom";
import Login from "./Pages/Login";
import Header from "./Components/Header";
import Home from "./Pages/Home";
import AuthenticatedRoute from "./Pages/AuthenticatedRoute";
import ErrorHandler from "./Components/ErrorHandler";
import Loader from "./Components/Loader";
import LegacyPromoCodeGenerator from "./Pages/LegacyPromoCodeGenerator";
import NotificationManager from "./Pages/NotificationManager";
import TokenManagement from "./Components/TokenManagement";
import NotificationInformation from "./Pages/NotificationInformation";

const App: React.FC = () => {

	return (
		<PersistGate persistor={persistor}>
			<BrowserRouter>
				<TokenManagement/>
				<ErrorHandler/>
				<Loader/>

				<Header/>

				<main>
					<Switch>
						<Route path="/" exact component={Login}/>
						<AuthenticatedRoute path="/home" exact component={Home}/>
						<AuthenticatedRoute path="/legacy_promo_code_generator" exact component={LegacyPromoCodeGenerator}/>
						<AuthenticatedRoute path="/notification_manager" exact component={NotificationManager}/>
						<AuthenticatedRoute path="/notification_prompt_manager" exact component={NotificationInformation}/>
						<Redirect to="/"/>
					</Switch>
				</main>
			</BrowserRouter>
		</PersistGate>
	);
};

export default App;

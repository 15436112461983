import React, {useEffect} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {DefaultApi, Token} from "client";
import {logout} from "../redux/meta/MetaActions";
import {getConfig} from "../services/clientApis";
interface IProps {
	token?: Token;
	dispatch?: any;
}

const TokenManagement: React.FC<IProps> = (props) => {

	useEffect(() => {
		checkToken().then();
		const interval = setInterval(checkToken,60000);
		return () => clearInterval(interval)
	}, [JSON.stringify(props.token)])

	async function checkToken() {
		// don't need to check on a token existing
		if (!props.token) {
			return
		}

		try {
			const {expired} = await new DefaultApi(getConfig(props.token)).isTokenExpiredPost({token: props.token});

			if (expired === true) {
				props.dispatch(logout());
			}
		} catch (err) {
			console.log(err);
		}
	}

	return null;
};

export default connect((store: IStore, props: IProps) => {
	return {
		...props,
		token: store.metaStore.token
	}
})(TokenManagement);
import React, {ReactNode, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Button, Input, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import cloneDeep from "lodash.clonedeep";
import ReorderButtons from "./ReorderButtons";
import {FiX} from "react-icons/all";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import {NotificationQuestionApi, Token} from "client";
import {getConfig} from "../services/clientApis";

interface IProps {
	dispatch?: any;
	token?: Token;
	isOpen: boolean;
	onDone(): Promise<void>;
	onClose(): void; 
}

const AddNewNotificationPromptListModal: React.FC<IProps> = (props) => {

	const [items, setItems] = useState<string[]>([""]);
	const [renderKey, setRenderKey] = useState(1);

	async function saveList(): Promise<void> {
		props.dispatch(incrementLoading());

		const newItems = cloneDeep(items).filter((item: string): boolean => {
			return item && item.length > 0;
		});

		setItems(newItems);

		try {
			await new NotificationQuestionApi(getConfig(props.token)).notificationQuestionAddNewNotificationQuestionPost({
				addNewNotificationQuestionBody: {
					textArray: newItems,
				},
			});

			await props.onDone();
			resetAndClose();
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	function resetAndClose(): void {
		setItems([""]);
		props.onClose();
	}

	/**
	 * Add new item to the string array.
	 *
	 */
	function addItem(): void {
		const newItems = cloneDeep(items);
		newItems.push("");
		setItems(newItems);
	}

	function makeInputs(_items: string[] = []): ReactNode {
		return _items.map((item: string, i: number) => {

			function onChangeHelper(e): void {
				const newItems = cloneDeep(items);
				newItems[i] = e.target.value;
				setItems(newItems);
			}

			function reOrderHelper(up: boolean): () => void {
				return () => {
					if (
						(items.length < 2) ||
						(up && i === 0) ||
						(!up && i >= (items.length - 1))
					) {
						return;
					}

					const newItems = cloneDeep(items);
					const temp = newItems[i];

					if (up) {
						newItems[i] = newItems[i - 1]
						newItems[i - 1] = temp;
					} else {
						newItems[i] = newItems[i + 1]
						newItems[i + 1] = temp;
					}

					setItems(newItems);
					setRenderKey(renderKey + 1);
				}
			}

			function deleteHelper(e): void {
				if (e) {
					e.preventDefault();
				}
				const newItems = cloneDeep(items);
				newItems.splice(i, 1);
				setItems(newItems);
				setRenderKey(renderKey + 1);
			}

			return (
				<div key={`dynamic-notification-list-item_${i}`} className="d-flex align-items-center mb-2">
					<span className="mr-2" style={{fontSize: "2rem"}}>
						•
					</span>

					<Input
						placeholder="Enter reason..."
						value={item}
						onChange={onChangeHelper}
					/>

					<div className="d-flex">
						<ReorderButtons
							upCallback={reOrderHelper(true)}
							downCallback={reOrderHelper(false)}
						/>

						<a href="" onClick={deleteHelper} className="ml-4 text-danger">
							<FiX size="1.5rem" style={{maxHeight: 40}}/>
						</a>
					</div>
				</div>
			);
		});
	}

	return (
		<Modal
			isOpen={props.isOpen}
			centered={true}
			fade={true}
		>
			<ModalHeader toggle={resetAndClose}>Add New List</ModalHeader>

			<ModalBody>
				<div key={renderKey}>
					{makeInputs(items)}
				</div>

				<div className="d-flex justify-content-center">
					<Button
						color="link"
						onClick={addItem}
					>
						+ Add item
					</Button>
				</div>
			</ModalBody>

			<ModalFooter>
				<Button
					color="success"
					onClick={saveList}
				>
					Save List
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(AddNewNotificationPromptListModal);

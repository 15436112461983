import React, {useState} from "react";
import {Button, Col, Input, Label, Row} from "reactstrap";
import ConfirmRemovalModal from "./ConfirmRemovalModal";
import {PurchaseModuleScreenOptions} from "client";

interface IPurchaseScreenOptionProps {
	onRemove(): void;
	onChange(key: keyof PurchaseModuleScreenOptions, asNumber?: boolean): any;
	values: PurchaseModuleScreenOptions;
}

const PurchaseScreenOption: React.FC<IPurchaseScreenOptionProps> = (props: IPurchaseScreenOptionProps) => {

	const {onRemove, onChange, values} = props;
	const [showRemovalModal, setShowRemovalModal] = useState(false);

	function showModal(): void {
		setShowRemovalModal(true);
	}

	function hideModal(): void {
		setShowRemovalModal(false);
	}

	function onRemoveHelper(): void {
		setShowRemovalModal(false);
		onRemove();
	}

	return (
		<React.Fragment>
			<ConfirmRemovalModal
				removalType="Purchase Option"
				show={showRemovalModal}
				closeModal={hideModal}
				confirmRemoval={onRemoveHelper}
			/>

			<Row className="mb-4">
				<Label xs={12} sm={6}>Product ID</Label>
				<Col xs={12} sm={6}>
					<Input type="text" placeholder="Product ID" value={values.productID} onChange={onChange("productID")}/>
				</Col>
			</Row>

			<Row className="mb-4">
				<Label xs={12} sm={6}>Price</Label>
				<Col xs={12} sm={6}>
					<Input type="number" placeholder="Price" value={values.price} onChange={onChange("price", true)}/>
				</Col>
			</Row>

			<Row className="mb-4">
				<Label xs={12} sm={6}>Description</Label>
				<Col xs={12} sm={6}>
					<Input type="text" placeholder="Description" value={values.description} onChange={onChange("description")}/>
				</Col>
			</Row>

			<div className="d-flex justify-content-end">
				<Button color="link" className="text-danger" onClick={showModal}>
					Remove this Purchase Option
				</Button>
			</div>
		</React.Fragment>
	);
};

export default PurchaseScreenOption;

import React, {useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Button, Col, Input, Label, Modal, Row} from "reactstrap";
import {AdminApi, APIError} from "client";
import {getConfig} from "../services/clientApis";
import {addError, updateImageList} from "../redux/meta/MetaActions";

interface IUploadAssetForm {
	name: string;
	file: any;
}

interface IUploadAssetModalProps {
	token?: any;
	dispatch?: any;
	show: boolean;
	closeModal(): void;
}

const UploadAssetModal: React.FC<IUploadAssetModalProps> = (props: IUploadAssetModalProps) => {

	const {token, dispatch, show, closeModal} = props;
	const [form, setForm] = useState<IUploadAssetForm>({name: "", file: null});

	/**
	 * Handle the name field changing
	 *
	 * @param key
	 */
	function createOnChange(key: keyof IUploadAssetForm): any {
		return (e) => {
			setForm({
				...form,
				[key]: e.target.value,
			});
		}
	}

	/**
	 * Handle the file field changing
	 *
	 * @param key
	 */
	function onFileChange(key: keyof IUploadAssetForm): any {
		return (e) => {
			setForm({
				...form,
				[key]: e.target.files[0],
			});
		}
	}

	/**
	 * Upload the new asset
	 *
	 */
	async function attemptUpload(): Promise<void> {
		let res;
		try {
			res = await new AdminApi(getConfig(token)).adminFormsUploadAssetPost({
				name: form.name,
				image: form.file,
			});
		} catch (e) {
			const error: APIError = await e.json();
			props.dispatch(addError(error.messages.join("\n")));
			return;
		}

		await getImageListAfterUpload();
	}

	/**
	 * Call after successful upload of new asset,
	 * call api to fetch list of assets again to update redux with.
	 * Then call closeModal() from props.
	 *
	 */
	async function getImageListAfterUpload(): Promise<void> {
		let res;
		try {
			res = await new AdminApi(getConfig(token)).adminGetAssetListGet();
		} catch (e) {
			const error: APIError = await e.json();
			props.dispatch(addError(error.messages.join("\n")));
			return;
		}

		props.dispatch(updateImageList(res));
		closeModal();
	}

	return (
		<Modal
			fade={true}
			centered={true}
			isOpen={show}
			onClose={closeModal}
			scrollable={true}
			contentClassName="p-3"
		>
			<h3>
				Upload a new asset
			</h3>

			<Row className="mb-4">
				<Label xs={12} sm={6}>Asset Name</Label>
				<Col xs={12} sm={6}>
					<Input type="text" placeholder="Asset Name" value={form.name}
					       onChange={createOnChange("name")}/>
				</Col>
			</Row>

			<Row className="mb-4">
				<Label xs={12} sm={6}>Asset File</Label>
				<Col xs={12} sm={6}>
					<Input type="file" placeholder="Asset File" onChange={onFileChange("file")}/>
				</Col>
			</Row>

			<div className="d-flex justify-content-between">
				<Button color="link" onClick={closeModal}>
					Cancel
				</Button>

				<Button color="primary" onClick={attemptUpload}>
					Upload Asset
				</Button>
			</div>
		</Modal>
	);
};

export default connect((store: IStore, props: IUploadAssetModalProps) => {
	return {
		token: store.metaStore.token,
	}
})(UploadAssetModal);

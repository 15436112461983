import React, {ReactNode, useEffect, useRef, useState} from "react";
import {Button, Card, CardBody, CardHeader, Col, Input, Label, Row} from "reactstrap";
import SelectOptions, {ISelectOptions} from "./SelectOptions";
import {Asset, DefaultApi, Module, ModuleEntry, PurchaseModuleScreenOptions} from "client";
import cloneDeep from "lodash.clonedeep";
import PurchaseScreenOption from "./PurchaseScreenOption";
import ModuleEntryForm from "./ModuleEntry";
import ConfirmRemovalModal from "./ConfirmRemovalModal";
import UploadAssetModal from "./UploadAssetModal";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {findJsonIndexInArray} from "../utils/arrays";
import {FiArrowDown, FiArrowUp} from "react-icons/all";
import ReorderButtons from "./ReorderButtons";
import {usePrevious} from "../utils/usePrevious";


/** Default forms for concatenating new item when "adding" new purchase option/sub module/entry **/

const defaultPurchaseOption: PurchaseModuleScreenOptions = {
	description: "",
	price: 0,
	productID: "",
};

const defaultEntry: ModuleEntry = {
	title: "",
	description: "",
	streamURL: "",
	streamAndroidURL: "",
	downloadURL: "",
};

export const defaultModule: Module = {
	productID: "",
	shortTitle: "",
	title: "",
	description: "",
	purchaseScreenIntro: "",
	purchaseScreenOption: [defaultPurchaseOption],
	iconID: "",
	headerID: "",
	subModules: [],
	draft: true,
	free: false,
	entries: [],
};

interface ICreateNewModuleFormProps {
	values: Module;
	isSubModule: boolean;
	imageList?: Array<Asset>;

	onChange(key: keyof Module, v: any): void;

	onRemove?(): void;

	onReOrder?(up: boolean): void;

	onSave?(): void;
}

const _CreateNewModuleForm: React.FC<ICreateNewModuleFormProps> = (props: ICreateNewModuleFormProps) => {

	const {values, isSubModule, imageList, onChange, onRemove, onSave} = props;
	const [hidePaymentOps, setHidePaymentOps] = useState(true);
	const [hideSubModules, setHideSubModules] = useState(false);
	const [hideEntries, setHideEntries] = useState(true);
	const [showRemovalModal, setShowRemovalModal] = useState(false);
	const [showUploadAssetModal, setShowUploadAssetModal] = useState();
	const [iconPreview, setIconPreview] = useState("");
	const [headerPreview, setHeaderPreview] = useState("");
	const [keyTracker, setKeyTracker] = useState(1);

	const previousValue: Module = usePrevious(cloneDeep(values));
	useEffect(() => {
		if (values && (!previousValue || (values.iconID !== previousValue.iconID || values.headerID !== previousValue.headerID))) {
			getImagePreview("iconID", values.iconID).then().catch();
			getImagePreview("headerID", values.headerID).then().catch();
		}
	});

	/** Functions for standardizing data before passing to prop onChange function **/

	/**
	 * Generic field onChange
	 *
	 * @param key
	 */
	function fieldChangeHelper(key: keyof Module): any {
		return (e) => {

			if (key === "iconID" || key === "headerID") {
				getImagePreview(key, e.target.value).then().catch();
			}

			onChange(key, e.target.value);
		};
	}

	/**
	 * CheckBox fields toggling
	 *
	 * @param key
	 */
	function checkBoxChangeHelper(key: keyof Module): any {
		return () => {
			onChange(key, !values[key]);
		}
	}

	/** Managing the Remove Modal for when this form is used as a Sub Module **/

	function showModal(): void {
		setShowRemovalModal(true);
	}

	function hideModal(): void {
		setShowRemovalModal(false);
	}

	function onRemoveHelper(): void {
		setShowRemovalModal(false);
		if (onRemove) {
			onRemove();
		}
	}

	/** Misc **/

	/**
	 * Toggles if the upload asset modal is visible
	 *
	 */
	function toggleUploadAssetModal(): void {
		setShowUploadAssetModal(!showUploadAssetModal);
	}

	/**
	 * Parse the imageList into usable data for the drop downs
	 *
	 */
	let assetsFullOptionsList: Array<ISelectOptions> = [];
	if (imageList && imageList.length > 0) {
		assetsFullOptionsList = imageList.map((image: Asset, i: number) => {
			return {
				value: image.id,
				text: image.name,
			};
		});
	}

	/**
	 * Queries the image list based on selection to show a preview
	 *
	 * @param key
	 * @param id
	 */
	async function getImagePreview(key: "iconID" | "headerID", id: string): Promise<void> {

		const index: number = findJsonIndexInArray(imageList, "id", id);

		if (index < 0) {
			if (key === "iconID") {
				setIconPreview("");
			} else if (key === "headerID") {
				setHeaderPreview("");
			}
			return;
		}

		const url: string = imageList[index].url;

		if (key === "iconID") {
			setIconPreview(url);
		} else if (key === "headerID") {
			setHeaderPreview(url);
		}
	}

	/** UI control **/

	function togglePaymentOpsVisible(): void {
		setHidePaymentOps(!hidePaymentOps);
	}

	function toggleSubModulesVisible(): void {
		setHideSubModules(!hideSubModules);
	}

	function toggleEntriesVisible(): void {
		setHideEntries(!hideEntries);
	}

	/** Purchase Options Functions **/

	/**
	 * onChange for the fields in the purchase option list
	 *
	 * @param i
	 * @param key
	 * @param value
	 */
	function purchaseOptionChangeHelper(i: number, key: keyof PurchaseModuleScreenOptions, value: any, asNumber: boolean = false): void {
		const pos: Array<PurchaseModuleScreenOptions> = cloneDeep(values.purchaseScreenOption);
		// @ts-ignore
		pos[i][key] = asNumber ? parseFloat(value) : value;
		onChange("purchaseScreenOption", pos);
	}

	/**
	 * Add a new purchase option
	 *
	 */
	function addPurchaseOption(): void {
		const pos: Array<PurchaseModuleScreenOptions> = cloneDeep(values.purchaseScreenOption).concat([defaultPurchaseOption]);
		onChange("purchaseScreenOption", pos);
	}

	/**
	 * Remove a purchase option
	 *
	 * @param index
	 */
	function removePurchaseOption(index: number): void {
		const pos: Array<PurchaseModuleScreenOptions> = cloneDeep(values.purchaseScreenOption);
		pos.splice(index, 1);
		onChange("purchaseScreenOption", pos);
	}

	/**
	 * Make the dynamic fields for the purchase options
	 *
	 * @param pos
	 */
	function mapPurchaseOptions(pos: Array<PurchaseModuleScreenOptions> = []): ReactNode {
		return pos.map((po: PurchaseModuleScreenOptions, i: number) => {

			function removeHelper(): void {
				removePurchaseOption(i);
			}

			function changeHelper(key: keyof PurchaseModuleScreenOptions, asNumber: boolean = false): any {
				return (e) => {
					purchaseOptionChangeHelper(i, key, e.target.value, asNumber);
				}
			}

			return (
				<Card className="my-3" key={`purchase-option-${i}`}>
					<CardBody>
						<PurchaseScreenOption
							onRemove={removeHelper}
							onChange={changeHelper}
							values={values.purchaseScreenOption[i]}
						/>
					</CardBody>
				</Card>
			);
		})
	}

	/** Nested SubModule Functions **/

	/**
	 * function for re-ordering sub modules & entries
	 *
	 * @param up
	 * @param i
	 * @param component
	 */
	function reOrderSubmodule(up: boolean, i: number, component: "subModules" | "entries"): void {
		const subs: Array<Module> = cloneDeep(values[component]);
		if (
			(values[component].length < 2) ||
			(up && i === 0) ||
			(!up && i >= (values[component].length - 1))
		) {
			return;
		}

		const a: Module = cloneDeep(subs[i]);
		const swapIndex: number = up ? (i - 1) : (i + 1);
		const b: Module = cloneDeep(subs[swapIndex]);

		subs[i] = b;
		subs[swapIndex] = a;
		onChange(component, subs);
		setKeyTracker(keyTracker + 1);
	}

	/**
	 * onChange for the fields in a sub module
	 *
	 * @param i
	 * @param key
	 * @param value
	 */
	function subModuleChangeHelper(i: number, key: keyof Module, value: any): void {
		const subs: Array<Module> = cloneDeep(values.subModules);
		// @ts-ignore
		subs[i][key] = value;
		onChange("subModules", subs);
	}

	/**
	 * Add a new sub module
	 *
	 */
	function addSubModule(): void {
		const subs: Array<Module> = cloneDeep(values.subModules).concat([defaultModule]);
		onChange("subModules", subs);
	}

	/**
	 * Remove a sub module
	 *
	 * @param index
	 */
	function removeSubModule(index: number): void {
		const subs: Array<Module> = cloneDeep(values.subModules);
		subs.splice(index, 1);
		onChange("subModules", subs);
	}

	/**
	 * Iterate and return ui for the submodules
	 *
	 * @param subs
	 */
	function mapSubModules(subs: Array<Module> = []): ReactNode {
		return subs.map((sub: Module, i: number) => {

			function removeHelper(): void {
				removeSubModule(i);
			}

			function changeHelper(key: keyof Module, e: any): any {
				subModuleChangeHelper(i, key, e);
			}

			function reOrderHelper(up: boolean): void {
				reOrderSubmodule(up, i, "subModules");
			}

			return (
				<div className="my-3">
					<CreateNewModuleForm
						key={`sub-module-${i}`}
						values={values.subModules[i]}
						isSubModule={true}
						onChange={changeHelper}
						onRemove={removeHelper}
						onReOrder={reOrderHelper}
					/>
				</div>
			)
		});
	}

	/** Module Entry Functions **/

	/**
	 * onChange for the fields in the module entry list
	 *
	 * @param i
	 * @param key
	 * @param value
	 */
	function moduleEntryChangeHelper(i: number, key: keyof ModuleEntry, value: any): void {
		const entries: Array<ModuleEntry> = cloneDeep(values.entries);
		// @ts-ignore
		entries[i][key] = value;
		onChange("entries", entries);
	}

	/**
	 * Add a new module entry
	 *
	 */
	function addModuleEntry(): void {
		const entries: Array<ModuleEntry> = cloneDeep(values.entries).concat([defaultEntry]);
		onChange("entries", entries);
	}

	/**
	 * Remove a module entry
	 *
	 */
	function removeModuleEntry(index: number): void {
		const entries: Array<ModuleEntry> = cloneDeep(values.entries);
		entries.splice(index, 1);
		onChange("entries", entries);
	}

	/**
	 * Make the dynamic fields for the entries of the module
	 *
	 * @param entries
	 */
	function mapModuleEntries(entries: Array<ModuleEntry> = []): ReactNode {
		return entries.map((entry: ModuleEntry, i: number) => {

			function removeHelper(): void {
				removeModuleEntry(i);
			}

			function changeHelper(key: keyof ModuleEntry): any {
				return (e) => {
					moduleEntryChangeHelper(i, key, e.target.value);
				}
			}

			function setToHeaderOnly(): any {
				const entries: Array<ModuleEntry> = cloneDeep(values.entries);
				for (let key of Object.keys(entries[i])) {
					if (key !== "title") {
						entries[i][key] = "";
					}
				}
				onChange("entries", entries);
			}

			function reOrderHelper(up: boolean): void {
				reOrderSubmodule(up, i, "entries");
			}

			return (
				<Card className="my-3" key={`module-entry-${i}`}>
					<CardBody>
						<ModuleEntryForm
							onRemove={removeHelper}
							onChange={changeHelper}
							values={values.entries[i]}
							setToHeaderOnly={setToHeaderOnly}
							onReOrder={reOrderHelper}
						/>
					</CardBody>
				</Card>
			);
		});
	}

	function subModuleReOrderIntercept(up: boolean): () => void {
		return () => {
			props.onReOrder(up);
		}
	}

	const checkBoxIdHelper: string = dynamicIdHelper(50);
	const draftCheckboxId: string = "draftCheckBox-" + checkBoxIdHelper;
	const freeCheckboxId: string = "freeCheckBox-" + checkBoxIdHelper;

	return (
		<React.Fragment>
			<ConfirmRemovalModal
				removalType="Sub Module & its contents"
				show={showRemovalModal}
				closeModal={hideModal}
				confirmRemoval={onRemoveHelper}
			/>

			<UploadAssetModal
				show={showUploadAssetModal}
				closeModal={toggleUploadAssetModal}
			/>

			<Card className="w-100" key={keyTracker}>
				<CardHeader>
					{isSubModule ?
						<div className="d-flex justify-content-between">
							<span>{values.title ? values.title : "New Sub Module"}</span>

							<ReorderButtons
								upCallback={subModuleReOrderIntercept(true)}
								downCallback={subModuleReOrderIntercept(false)}
							>
								Reorder Sub Module
							</ReorderButtons>
						</div>
						: (values.title ? values.title : "New Module")}
				</CardHeader>

				<CardBody>
					<Row className="mb-4">
						<Label xs={12} sm={6}>Product ID</Label>
						<Col xs={12} sm={6}>
							<Input type="text" placeholder="Product ID" value={values.productID}
							       onChange={fieldChangeHelper("productID")}/>
						</Col>
					</Row>

					<Row className="mb-4">
						<Label xs={12} sm={6}>Short Title</Label>
						<Col xs={12} sm={6}>
							<Input type="text" placeholder="Short Title" value={values.shortTitle}
							       onChange={fieldChangeHelper("shortTitle")}/>
						</Col>
					</Row>

					<Row className="mb-4">
						<Label xs={12} sm={6}>Title</Label>
						<Col xs={12} sm={6}>
							<Input type="text" placeholder="Title" value={values.title}
							       onChange={fieldChangeHelper("title")}/>
						</Col>
					</Row>

					<Row className="mb-4">
						<Label xs={12} sm={6}>Description</Label>
						<Col xs={12} sm={6}>
							<Input type="text" placeholder="Description" value={values.description}
							       onChange={fieldChangeHelper("description")}/>
						</Col>
					</Row>

					<Row className="mb-4">
						<Label xs={12} sm={6}>Purchase Screen Intro</Label>
						<Col xs={12} sm={6}>
							<Input type="text" placeholder="Purchase Screen Intro" value={values.purchaseScreenIntro}
							       onChange={fieldChangeHelper("purchaseScreenIntro")}/>
						</Col>
					</Row>

					<hr/>
					<Row className="mb-4">
						<Label xs={12} sm={6}>Icon (1024x1024, try to keep up 1mb if png, and under 250kb if jpeg)</Label>
						<Col xs={12} sm={6}>
							<Input type="select" value={values.iconID} onChange={fieldChangeHelper("iconID")}>
								<option value="" selected>Select an Icon</option>
								<hr/>
								<SelectOptions fullOps={assetsFullOptionsList}/>
							</Input>
						</Col>
						<Col className="w-100 d-flex justify-content-end">
							<img
								className="my-2"
								style={{
									objectFit: "contain",
									maxWidth: "100%",
									maxHeight: "200px",
								}}
								src={iconPreview}
							/>
						</Col>
					</Row>

					<Row className="mb-4">
						<Label xs={12} sm={6}>Header Image (1280x320, try to keep under 1mb if png, and under 150kb if jpeg)</Label>
						<Col xs={12} sm={6}>
							<Input type="select" value={values.headerID} onChange={fieldChangeHelper("headerID")}>
								<option value="" selected>Select an Image</option>
								<hr/>
								<SelectOptions fullOps={assetsFullOptionsList}/>
							</Input>
						</Col>
						<Col className="w-100 d-flex justify-content-end">
							<img
								className="my-2"
								style={{
									objectFit: "contain",
									maxWidth: "100%",
									maxHeight: "200px",
								}}
								src={headerPreview}
							/>
						</Col>
					</Row>

					<div className="d-flex justify-content-end mb-4">
						<Button color="primary" onClick={toggleUploadAssetModal}>
							Add New Icon/Image
						</Button>
					</div>
					<hr/>

					<Row className="mb-4">
						<Col xs={12} className="d-flex align-items-center">
							<h5>Purchase Screen Options</h5>
							<Button color="link" onClick={togglePaymentOpsVisible} className="d-flex">
								{hidePaymentOps ? "Show Section" : "Hide Section"}
							</Button>
						</Col>
						<Col xs={12} className={hidePaymentOps ? "d-none" : "d-initial"}>
							{mapPurchaseOptions(values.purchaseScreenOption)}
						</Col>
						<Col xs={12} className={(hidePaymentOps ? "d-none" : "d-flex") + " justify-content-center"}>
							<Button color="link" onClick={addPurchaseOption}>
								Add Purchase Option
							</Button>
						</Col>
					</Row>

					<hr/>

					{/*{!isSubModule && (*/}
					<React.Fragment>
						<Row className="mb-4">
							<Col xs={12} className="d-flex align-items-center">
								<h5>Sub Modules</h5>
								<Button color="link" onClick={toggleSubModulesVisible} className="d-flex">
									{hideSubModules ? "Show Section" : "Hide Section"}
								</Button>
							</Col>
							<Col xs={12} className={hideSubModules ? "d-none" : "d-initial"}>
								{mapSubModules(values.subModules)}
							</Col>
							<Col xs={12} className={(hideSubModules ? "d-none" : "d-flex") + " justify-content-center"}>
								<Button color="link" onClick={addSubModule}>
									Add Sub Module
								</Button>
							</Col>
						</Row>

						<hr/>
					</React.Fragment>
					{/*)}*/}

					<Row className="mb-4">
						<Col xs={12} className="d-flex align-items-center">
							<h5>Entries</h5>
							<Button color="link" onClick={toggleEntriesVisible} className="d-flex">
								{hideEntries ? "Show Section" : "Hide Section"}
							</Button>
						</Col>
						<Col xs={12} className={hideEntries ? "d-none" : "d-initial"}>
							{mapModuleEntries(values.entries)}
						</Col>
						<Col xs={12} className={(hideEntries ? "d-none" : "d-flex") + " justify-content-center"}>
							<Button color="link" onClick={addModuleEntry}>
								Add Entry
							</Button>
						</Col>
					</Row>

					<hr/>

					<Row className="mb-4">
						<Label xs={6}>Draft</Label>
						<Col xs={6}>
							<p>
								<input type="checkbox" name={draftCheckboxId} id={draftCheckboxId} checked={values.draft}
								       onChange={checkBoxChangeHelper("draft")}/>
								<label htmlFor={draftCheckboxId}/>
							</p>
						</Col>
					</Row>

					<Row className="mb-4">
						<Label xs={6}>This module is free</Label>
						<Col xs={6}>
							<p>
								<input type="checkbox" name={freeCheckboxId} id={freeCheckboxId} checked={values.free}
								       onChange={checkBoxChangeHelper("free")}/>
								<label htmlFor={freeCheckboxId}/>
							</p>
						</Col>
					</Row>

					<div className="d-flex justify-content-end">
						{isSubModule ? (
							<Button color="link" className="text-danger" onClick={showModal}>
								Remove this Sub Module
							</Button>
						) : (
							<React.Fragment>
								{/*<Button color="link" onClick={onSave}>*/}
								{/*	Save Module*/}
								{/*</Button>*/}
							</React.Fragment>
						)}
					</div>
				</CardBody>
			</Card>
		</React.Fragment>
	)
};

/**
 * Help generate unique id for the checkboxes, because they rely on the id attribute.
 * Couldn't use index placement in sub-modules reliably in case of multiple nested levels, resulting in matching ids at matching indexes,
 * so went with random strings.
 *
 * @param length
 */
function dynamicIdHelper(length) {
	let result           = '';
	let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	let charactersLength = characters.length;
	for ( let i = 0; i < length; i++ ) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}

_CreateNewModuleForm.defaultProps = {
	imageList: [],
};

export const CreateNewModuleForm = connect((store: IStore, props: ICreateNewModuleFormProps) => {
	return {
		imageList: store.metaStore.imageList,
		...props,
	}
})(_CreateNewModuleForm);


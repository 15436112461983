import React, {useEffect, useState} from "react";
import {Button, CardBody, CardHeader, CardText, Col, Container, Row, Card} from "reactstrap";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {NotificationQuestion, NotificationQuestionApi, Token} from "client";
import {getConfig} from "../services/clientApis";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import AddNewNotificationPromptListModal from "../Components/AddNewNotificationPromptListModal";
import moment from "moment";

interface IProps {
	token: Token;
	dispatch: any;
}

const NotificationInformation: React.FC<IProps> = (props) => {

	const [notificationData, setNotificationData] = useState<Array<NotificationQuestion>>([]);
	const [showAdd, setShowAdd] = useState(false);

	const notificationDataSorted = notificationData
		.sort((a, b) => {
			return b.creationDate - a.creationDate;
		})
		.sort((a, b) => {
		return a.disabled === b.disabled ? 0 : a.disabled ? 1 : -1;
		})

	useEffect(() => {
		getNotificationData().then().catch();
	}, [])

	async function getNotificationData() {
		props.dispatch(incrementLoading());
		try {
			const res = await new NotificationQuestionApi(getConfig(props.token)).notificationQuestionGetNotificationQuestionDataGet();
			setNotificationData(res);
		} catch (err) {
			props.dispatch(addError(err));
		}
		props.dispatch(decrementLoading());
	}

	function toggleAddModal(): void {
		setShowAdd(!showAdd);
	}

	function createNotificationQuestion(notificationQuestion: NotificationQuestion, i: number) {

		function createText(point: string, k: number) {
			return (
				<CardText key={"notification-" + i + "-" + k}>
					- {point}
				</CardText>
			)
		}

		async function disable() {
			props.dispatch(incrementLoading());
			try {
				await new NotificationQuestionApi(getConfig(props.token)).notificationQuestionToggleNotificationPut({
					toggleNotificationRequestBody: {
						id: notificationQuestion.id,
						disable: !notificationQuestion.disabled
					},
				});
				await getNotificationData();
			} catch (err) {
				props.dispatch(addError(err));
			}
			props.dispatch(decrementLoading());
		}

		return (
			<Col md={6} lg={4} className="mb-3">
				<Card style={{height: "100%"}}>
					<CardHeader>ID: {notificationQuestion.id} <b className={notificationQuestion.disabled ? "text-danger" : "text-success"}>({notificationQuestion.disabled ? "Disabled" : "Enabled"})</b></CardHeader>
					<CardBody className="d-flex flex-column justify-content-between">
						<div>
							<CardText>
								<b>Sent:</b> {notificationQuestion.numberSent}
							</CardText>
							<CardText className="text-success">
								<b>Enabled:</b> {notificationQuestion.numberEnabled} {notificationQuestion.numberSent > 0 && "(" + (notificationQuestion.numberEnabled / notificationQuestion.numberSent * 100).toFixed(2)+"%)"}
							</CardText>
							<CardText className="text-danger">
								<b>Skipped:</b> {notificationQuestion.numberSkipped} {notificationQuestion.numberSent > 0 && "(" + (notificationQuestion.numberSkipped / notificationQuestion.numberSent * 100).toFixed(2)+"%)"}
							</CardText>
							<CardText className="text-danger">
								<b>App Close:</b> {notificationQuestion.numberSent - notificationQuestion.numberSkipped - notificationQuestion.numberEnabled} {notificationQuestion.numberSent > 0 && "(" + ((notificationQuestion.numberSent - notificationQuestion.numberSkipped - notificationQuestion.numberEnabled) / notificationQuestion.numberSent * 100).toFixed(2)+"%)"}
							</CardText>
							<CardText>
								<b>Created:</b> {moment(notificationQuestion.creationDate).format("DD/MM/YYYY h:mm a")}
							</CardText>
							<hr/>
							{notificationQuestion?.textArray?.length > 0 ? (
								<React.Fragment>
									{notificationQuestion?.textArray.map(createText)}
								</React.Fragment>
							) : (
								<React.Fragment>
									<span>
										This entry intentionally empty & represents the "null hypothesis". If the system randomly chooses this option for a user, the custom alert will be skipped and they will go straight to the "native" alert.
									</span>
								</React.Fragment>
							)}
						</div>

						<div className="mt-3">
							<Button
								color={notificationQuestion.disabled ? "primary" : "danger"}
								onClick={disable}>
								{notificationQuestion.disabled ? "Enable" : "Disable"}
							</Button>
						</div>
					</CardBody>
				</Card>
			</Col>
		)
	}

	return (
		<React.Fragment>
			<AddNewNotificationPromptListModal
				isOpen={showAdd}
				onDone={getNotificationData}
				onClose={toggleAddModal}
			/>

			<Container className="my-5">
				<div className="mb-4">
					<Button
						color="primary"
						onClick={toggleAddModal}
					>
						Add New List
					</Button>
					<p className="mt-2">If a user has a back button on their Android and tries to dismiss the modal with their back button, it will count as an App Close.</p>
				</div>

				<Row>
					{notificationDataSorted.map(createNotificationQuestion)}
				</Row>
			</Container>
		</React.Fragment>
	);
}

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(NotificationInformation)

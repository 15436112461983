import React from "react";
import {Button, Card, CardBody, CardHeader, Input, Label} from "reactstrap";
import SelectOptions, {ISelectOptions} from "./SelectOptions";
import {Module} from "client";

interface ISelectModuleOrNewProps {
	modules: Array<Module>
	selectedExisting: number;
	onSelectExisting(selection: any): void;
	onClickCreateNew(): void;
}

const SelectModuleOrNew: React.FC<ISelectModuleOrNewProps> = (props: ISelectModuleOrNewProps) => {

	const {modules, selectedExisting, onSelectExisting, onClickCreateNew} = props;

	function handleOnChangeOfExisting(key: string): any {
		return (e) => {
			onSelectExisting(e.target.value);
		}
	}

	const moduleList: ISelectOptions[] = modules.map((m: Module, i: number) => {
		return {
			value: i,
			text: m.title,
		}
	});

	return (
		<Card className="w-100 my-5">
			<CardHeader>
				Module Manager
			</CardHeader>

			<CardBody>
				<div className="mb-4">
					<p>
						Always click the "Save All Changes" button at the bottom of the page to update the database with your changes.
						<br/>
						<br/>
						If you are creating a new module and then switch to edit a pre-existing one without saving, your progress will be lost, so make sure to save before switching to a pre-existing module when making a new one.
					</p>

					<Label>Select an existing module to make changes:</Label>
					<Input type="select" value={selectedExisting} onChange={handleOnChangeOfExisting("existing")}>
						<option value={-1} selected>Select a Module</option>
						<hr/>
						<SelectOptions fullOps={moduleList}/>
					</Input>
				</div>
				<div>
					<Label>Or...</Label>
					<div>
						<Button color="primary" onClick={onClickCreateNew}>Create a New Module</Button>
					</div>
				</div>
			</CardBody>
		</Card>
	);
};

export default SelectModuleOrNew;

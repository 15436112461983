import React, {useState} from "react";
import {Link, NavLink, useLocation} from "react-router-dom";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Button, Collapse, Container, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem} from "reactstrap";
import {logout} from "../redux/meta/MetaActions";
import {DefaultApi} from "client";

interface IHeaderProps {
	token?: any;
	dispatch?: any;
}

const Header: React.FC<IHeaderProps> = (props: IHeaderProps) => {

	let location = useLocation();

	const {token, dispatch} = props;
	const [isOpen, setIsOpen] = useState(false);

	function toggle(): void {
		setIsOpen(!isOpen);
	}

	function loginHelper(): void {

	}

	async function logoutHelper(): Promise<void> {
		await new DefaultApi().unauthenticateTokenDelete({token});
		props.dispatch(logout());
	}

	if (location.pathname === "/") {
		return null;
	}

	return (
		<Navbar color="light" light expand="md">
			<Container>
				<NavbarBrand href="/" className="mr-auto">Grapplearts Admin Portal (v7)</NavbarBrand>

				<NavbarToggler onClick={toggle}/>

				<Collapse isOpen={isOpen} navbar>
					<Nav navbar className="ml-auto">
						<Link to="/home">
							<Button color="link">
								Home
							</Button>
						</Link>
						<Link to="/legacy_promo_code_generator">
							<Button color="link">
								Legacy Code Generator
							</Button>
						</Link>
						<Link to="/notification_manager">
							<Button color="link">
								Notification Manager
							</Button>
						</Link>
						<Link to="/notification_prompt_manager">
							<Button color="link">
								Notification Prompt Manager
							</Button>
						</Link>
						{token ? (
							<React.Fragment>
								<Button color="link" className="text-danger" onClick={logoutHelper}>
									Logout
								</Button>
							</React.Fragment>
						) : (
							<Link to="/">
								<Button color="link" onClick={loginHelper}>
									Login
								</Button>
							</Link>
						)}
					</Nav>
				</Collapse>
			</Container>
		</Navbar>
	)
};

export default connect((store: IStore, props: IHeaderProps) => {
	return {
		token: store.metaStore.token,
	}
})(Header);

import React, {ReactNode, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import cloneDeep from "lodash.clonedeep";
import {removeError} from "../redux/meta/MetaActions";
import {Button, Modal} from "reactstrap";

interface IErrorHandlerProps {
	errors?: string[];
	dispatch?: any;
}

let dismissEnabled: boolean = true;

const ErrorHandler: React.FC<IErrorHandlerProps> = (props: IErrorHandlerProps) => {

	const {errors, dispatch} = props;
	const [show, setShow] = useState<boolean[]>([]);

	useEffect(() => {
		setShow(Array(props.errors.length).fill(true));
	}, [props.errors]);

	function handleClose(index: number): void {
		dismissEnabled = false;
		const newShows = cloneDeep(show);
		newShows.splice(index, 1);
		setShow(newShows);

		function popE(): void {
			props.dispatch(removeError(index));
		}

		setTimeout(() => {
			dismissEnabled = true;
			popE();
		}, 400);
	}

	function makeErrors(errors: string[] = []): ReactNode {
		return errors.map((error: string, i: number) => {

			function dismiss(): void {
				if (dismissEnabled) {
					handleClose(i);
				}
			}

			return (
				<Modal
					fade={true}
					centered={true}
					isOpen={show[i]}
					contentClassName="text-dark overflow-hidden p-3"
					key={`${error}-modal-${i}`}
					scrollable={true}
					className="d-flex justify-content-center"
				>
					<div className="d-flex justify-content-center align-items-center">
						<h1 className="ml-3 mb-0">
							Error!
						</h1>
					</div>

					<p className="text-danger mt-3 text-center">
						{error}
					</p>

					<Button color="primary" className="mt-2" onClick={dismiss}>
						Dismiss
					</Button>
				</Modal>
			);
		});
	}

	return (
		<React.Fragment>
			{makeErrors(errors)}
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IErrorHandlerProps) => {
	return {
		errors: store.metaStore.errors,
		...props,
	}
})(ErrorHandler);

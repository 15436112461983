import {IAction} from "../index";
import InitialMetaStore, {IMetaStore} from "./InitialMetaStore";
import cloneDeep from "lodash.clonedeep";
import sortBy from "lodash.sortby";

export enum MetaType {
	LOGIN = "LOGIN",
	LOGOUT = "LOGOUT",
	LOADING = "LOADING",
	ADD_ERROR = "ADD_ERROR",
	REMOVE_ERROR = "REMOVE_ERROR",
	UPDATE_ASSETS = "UPDATE_ASSETS",
}

export default function(store: IMetaStore = InitialMetaStore, action: IAction<MetaType, any>): IMetaStore {

	const newStore: IMetaStore = cloneDeep(store);

	switch(action.type) {
		case MetaType.LOGIN:
			newStore.token = action.payload;
			break;
		case MetaType.LOGOUT:
			delete newStore.token;
			break;
		case MetaType.LOADING:
			if ((newStore.loadingCount + action.payload) >= 0) {
				newStore.loadingCount += action.payload;
			} else {
				newStore.loadingCount = 0;
			}
			break;
		case MetaType.ADD_ERROR:
			newStore.errors.push(action.payload);
			break;
		case MetaType.REMOVE_ERROR:
			newStore.errors.splice(action.payload, 1);
			break;
		case MetaType.UPDATE_ASSETS:
			newStore.imageList = sortBy(action.payload, "name");
			break;
	}

	return newStore;
}

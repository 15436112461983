import {MetaType} from "./MetaReducer";
import {IAction, store} from "../index";
import {Asset, DefaultApi, Token} from "client";

export function incrementLoading(): IAction<MetaType.LOADING, number> {
	return {
		type: MetaType.LOADING,
		payload: 1,
	}
}

export function decrementLoading(): IAction<MetaType.LOADING, number> {
	return {
		type: MetaType.LOADING,
		payload: -1,
	}
}

export function login(payload: any): IAction<MetaType.LOGIN, any> {
	return {
		type: MetaType.LOGIN,
		payload,
	}
}

export function logout(): IAction<MetaType.LOGOUT, undefined> {

	return {
		type: MetaType.LOGOUT,
	}
}

export function addError(payload: string = "Sorry, something went wrong."): IAction<MetaType.ADD_ERROR, string> {
	return {
		type: MetaType.ADD_ERROR,
		payload,
	}
}

export function removeError(payload: number = 0): IAction<MetaType.REMOVE_ERROR, number> {
	return {
		type: MetaType.REMOVE_ERROR,
		payload,
	}
}

export function updateImageList(payload: Array<Asset>): IAction<MetaType.UPDATE_ASSETS, Array<Asset>> {
	return {
		type: MetaType.UPDATE_ASSETS,
		payload,
	}
}

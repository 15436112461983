import React, {useEffect, useState} from "react";
import {ModuleEntry} from "client";
import ConfirmRemovalModal from "./ConfirmRemovalModal";
import {Button, Col, Input, Label, Row} from "reactstrap";
import ReorderButtons from "./ReorderButtons";

interface IModuleEntryFormProps {
	onRemove(): void;

	onChange(key: keyof ModuleEntry): any;

	setToHeaderOnly(): void;

	onReOrder?(up: boolean): void;

	values: ModuleEntry;
}

const ModuleEntryForm: React.FC<IModuleEntryFormProps> = (props: IModuleEntryFormProps) => {

	const {onRemove, onChange, setToHeaderOnly, values} = props;
	const [showRemovalModal, setShowRemovalModal] = useState(false);
	const [isHeader, setIsHeader] = useState(false);

	function showModal(): void {
		setShowRemovalModal(true);
	}

	function hideModal(): void {
		setShowRemovalModal(false);
	}

	function onRemoveHelper(): void {
		setShowRemovalModal(false);
		onRemove();
	}

	function toggleIsHeader(): () => void {
		return () => {
			if (!isHeader) {
				setToHeaderOnly();
			}
			setIsHeader(!isHeader);
		}
	}

	function entryReOrderIntercept(up: boolean): () => void {
		return () => {
			props.onReOrder(up);
		}
	}

	let entryIsTitle: boolean = true;
	let noValuesYet: boolean = true;
	for (let key of Object.keys(values)) {
		if (key !== "title" && values[key] && values[key].length > 0) {
			entryIsTitle = false;
		}

		if (values[key] && values[key].length > 0) {
			noValuesYet = false
		}
	}

	return (
		<React.Fragment>
			<ConfirmRemovalModal
				removalType="Entry"
				show={showRemovalModal}
				closeModal={hideModal}
				confirmRemoval={onRemoveHelper}
			/>

			<div className="d-flex justify-content-end">
				<ReorderButtons
					upCallback={entryReOrderIntercept(true)}
					downCallback={entryReOrderIntercept(false)}
				>
					Reorder Entry
				</ReorderButtons>
			</div>

			{/*<Row className="mb-4">*/}
			{/*	<Label xs={6}>This Entry is a Header</Label>*/}
			{/*	<Col xs={6}>*/}
			{/*		<p>*/}
			{/*			<input type="checkbox" name="isHeaderCheckBox" id="isHeaderCheckBox" checked={isHeader}*/}
			{/*			       onChange={toggleIsHeader()}/>*/}
			{/*			<label htmlFor="isHeaderCheckBox"/>*/}
			{/*		</p>*/}
			{/*	</Col>*/}
			{/*</Row>*/}

			<div>
				<p>
					To make a "header" entry, enter ONLY the <i>"Title"</i> field below, else please enter all fields
				</p>
				<p className="font-italic">
					{entryIsTitle ? (
						<React.Fragment>
							{noValuesYet ? (
								<span className="text-muted">
									No fields in this entry yet
								</span>
							) : (
								<span className="text-info">
									This entry is currently a title
								</span>
							)}
						</React.Fragment>
					) : (
						<span className="text-muted">
							This entry is NOT a title
						</span>
					)}
				</p>
			</div>

			<Row className="mb-4">
				<Label xs={12} sm={6}>Title</Label>
				<Col xs={12} sm={6}>
					<Input type="text" placeholder="Title" value={values.title} onChange={onChange("title")}/>
				</Col>
			</Row>

			{!isHeader && (
				<React.Fragment>
					<Row className="mb-4">
						<Label xs={12} sm={6}>Description</Label>
						<Col xs={12} sm={6}>
							<Input type="text" placeholder="Description" value={values.description}
							       onChange={onChange("description")}/>
						</Col>
					</Row>

					<Row className="mb-4">
						<Label xs={12} sm={6}>Stream URL</Label>
						<Col xs={12} sm={6}>
							<Input type="text" placeholder="Stream URL" value={values.streamURL}
							       onChange={onChange("streamURL")}/>
						</Col>
					</Row>

					<Row className="mb-4">
						<Label xs={12} sm={6}>Stream Android URL</Label>
						<Col xs={12} sm={6}>
							<Input type="text" placeholder="Stream Android URL" value={values.streamAndroidURL}
							       onChange={onChange("streamAndroidURL")}/>
						</Col>
					</Row>

					<Row className="mb-4">
						<Label xs={12} sm={6}>Download URL</Label>
						<Col xs={12} sm={6}>
							<Input type="text" placeholder="Download URL" value={values.downloadURL}
							       onChange={onChange("downloadURL")}/>
						</Col>
					</Row>
				</React.Fragment>
			)}

			<div className="d-flex justify-content-end">
				<Button color="link" className="text-danger" onClick={showModal}>
					Remove this Entry
				</Button>
			</div>
		</React.Fragment>
	);
};

export default ModuleEntryForm;

import {
	Button,
	Card,
	CardBody,
	CardHeader,
	CardText,
	FormGroup,
	Container,
	Input,
	Label,
	ButtonGroup, FormText
} from "reactstrap";
import React, {ChangeEventHandler, useEffect, useState} from "react";
import Select from 'react-select';
import CryptoJS from 'crypto-js'
import {
	IGAContainerApp,
	IGAContainerSubmoduleApp,
	IGAContainerTechnique,
	IGAContainerHeader, DefaultApi, APIError
} from "client";
import axios from "axios";
import {addError} from "../redux/meta/MetaActions";

const CIPHER_KEY_PASS = "Grapplearts";
const FREE_APP_URL_PREFIX = "bjjgrapplearts://"

enum DeepLinkType {
	URL,
	APP_LOCATION,
	NO_DEEP_LINK,
	PODCASTS
}

interface IDeepLink {
	deepLink: true,
	appId: string,
	nestedId?: string;
	videoID?: string;
}

const serverKey = "AAAA_YCpquk:APA91bH7IM2CrU41ruayqcs76VbimVSEV4Lj66MGGVfBBJFqi2_TJUmlsnRvv8aAe2C4dJ8nu0sGdXLnAE7wgwgD7lz4aHOZ0cv77wxDqcsLAPgx5neNNZ8Y4YSEfkfYLvgAsI4Fa-vqCo-qHEio59nlbZCb27b4pw";


type IOption = { value: IGAContainerApp | IGAContainerSubmoduleApp | IGAContainerTechnique, label: string, parent?: IGAContainerApp | IGAContainerSubmoduleApp; }

const NotificationManager: React.FC = () => {

	const [deepLinkType, setDeepLinkType] = useState<DeepLinkType>(DeepLinkType.NO_DEEP_LINK);
	const [selectedLocation, setSelectedLocation] = useState<string>();
	const [deepLink, setDeepLink] = useState<string>();
	const [options, setOptions] = useState<IOption[]>();
	const [body, setBody] = useState<string>();
	const [title, setTitle] = useState<string>();
	const [customURL, setCustomURL] = useState<string>();
	const [customURLHeader, setCustomURLHeader] = useState<string>();
	const [sending, setSending] = useState<boolean>(false);

	useEffect(() => {
		createOptions().then(obj => setOptions(obj));
	}, []);

	useEffect(() => {
		if (selectedLocation) {
			setDeepLink(prepareJSON(selectedLocation));
		} else {
			setDeepLink(undefined);
		}
	}, [JSON.stringify(selectedLocation)]);


	useEffect(() => {
		if (customURL || customURLHeader) {
			const encryptedCustomURL = CryptoJS.AES.encrypt(JSON.stringify({
				deepLink: true,
				customURL,
				customURLHeader,
			}), CIPHER_KEY_PASS);
			const encodedCustomURL = FREE_APP_URL_PREFIX + encodeURIComponent(encryptedCustomURL);

			setDeepLink(encodedCustomURL);
		} else {
			setDeepLink(undefined);
		}
	}, [customURL, customURLHeader]);

	useEffect(() => {
		if (deepLinkType === DeepLinkType.PODCASTS) {
			const encryptedCustomURL = CryptoJS.AES.encrypt(JSON.stringify({
				deepLink: true,
				page: "podcastScreen", // same string as the page is labelled in the app
				customURL: "https://www.grapplearts.com/tag/podcasts/",
				customURLHeader: "Podcasts",
			}), CIPHER_KEY_PASS);
			const encodedCustomURL = FREE_APP_URL_PREFIX + encodeURIComponent(encryptedCustomURL);

			setDeepLink(encodedCustomURL);
		} else {
			setDeepLink(undefined);
		}
	}, [deepLinkType]);

	const selectDeepLinkType = (type: DeepLinkType) => () => {
		if (type !== deepLinkType) {
			setSelectedLocation(undefined);
			setDeepLink(undefined);
			setDeepLinkType(type)
		}
	};

	const createOnChange = (set: (v) => void): ChangeEventHandler<HTMLInputElement> => (event) => {
		set(event.target.value)
	};

	async function sendNotification(): Promise<void> {
		setSending(true);
		try {
			switch (deepLinkType) {
				case DeepLinkType.NO_DEEP_LINK:
					await axios.post("https://fcm.googleapis.com/fcm/send", {
						// to: "eLTlKWJjDnI:APA91bEspladi1FFHQLEKoGA-cHNNj0l-t1yk_1iy9nWTd64w2l4r7BkO6TVmek_nifSp2qOQNK9p-oqYXrtF4gI-JwnRcDJHzf-kZk1LvWV_B_E4FjPhfY21FD0eokyH5anOzpfnt0N",
						to: "/topics/all",
						notification: {
							body,
							title,
						}
					}, {
						headers: {
							Authorization: `key=${serverKey}`,
							["Content-Type"]: "application/json",
						}
					});
					break;
				case DeepLinkType.URL:
					await axios.post("https://fcm.googleapis.com/fcm/send", {
						// to: "eLTlKWJjDnI:APA91bEspladi1FFHQLEKoGA-cHNNj0l-t1yk_1iy9nWTd64w2l4r7BkO6TVmek_nifSp2qOQNK9p-oqYXrtF4gI-JwnRcDJHzf-kZk1LvWV_B_E4FjPhfY21FD0eokyH5anOzpfnt0N",
						to: "/topics/all",
						data: {
							body,
							title,
							deepLink,
						},
						notification: {
							body,
							title,
						}
					}, {
						headers: {
							Authorization: `key=${serverKey}`,
							["Content-Type"]: "application/json",
						}
					});
					break;
				case DeepLinkType.APP_LOCATION:
					await axios.post("https://fcm.googleapis.com/fcm/send", {
						to: "/topics/all",
						// to: "eLTlKWJjDnI:APA91bEspladi1FFHQLEKoGA-cHNNj0l-t1yk_1iy9nWTd64w2l4r7BkO6TVmek_nifSp2qOQNK9p-oqYXrtF4gI-JwnRcDJHzf-kZk1LvWV_B_E4FjPhfY21FD0eokyH5anOzpfnt0N",
						data: {
							body,
							title,
							deepLink,
						},
						notification: {
							body,
							title,
						}
					}, {
						headers: {
							Authorization: `key=${serverKey}`,
							["Content-Type"]: "application/json",
						}
					});
					break;
				case DeepLinkType.PODCASTS:
					await axios.post("https://fcm.googleapis.com/fcm/send", {
						to: "/topics/all",
						// to: "eLTlKWJjDnI:APA91bEspladi1FFHQLEKoGA-cHNNj0l-t1yk_1iy9nWTd64w2l4r7BkO6TVmek_nifSp2qOQNK9p-oqYXrtF4gI-JwnRcDJHzf-kZk1LvWV_B_E4FjPhfY21FD0eokyH5anOzpfnt0N",
						data: {
							body,
							title,
							deepLink,
						},
						notification: {
							body,
							title,
						}
					}, {
						headers: {
							Authorization: `key=${serverKey}`,
							["Content-Type"]: "application/json",
						}
					})
			}
		} catch (err) {
			alert(JSON.stringify(err));
		}

		setSending(false);

	}

	useEffect(() => {
		console.log("selectedLocation:", selectedLocation)
	}, [selectedLocation]);

	function createPodcastsDeepLink(): void {

	}

	function prepareJSON(selection: any): string {

		const j: Partial<IDeepLink> = {deepLink: true};

		if ((!selection.value.nested && selection.value.techniques) || (selection.value.nested && selection.value.apps)) {
		// if (!selection.value.nested && (selection.value.apps || selection.value.techniques)) {
			// selected a module
			j.appId = selection.value.id;
		} else if (selection.value.nested) {
			// selected a sub-module
			j.appId = selection.value.parentId;
			j.nestedId = selection.value.id;
		} else {
			// selected a video
			j.videoID = selection.value.id;
			if (selection.parent.nested) {
				// selected a video that belongs to a submodule
				j.appId = selection.parent.parentId;
				j.nestedId = selection.parent.id;
			} else {
				// selected a video that belongs to a module with no submodules
				j.appId = selection.parent.id;
			}
		}
		console.log("j:", j);
		const encrypted = CryptoJS.AES.encrypt(JSON.stringify(j), CIPHER_KEY_PASS);
		const encoded = FREE_APP_URL_PREFIX + encodeURIComponent(encrypted);

		return encoded
	}

	return (
		<Container>
			<Card className="mt-5 mb-5">
				<CardHeader>
					Notification Manager
				</CardHeader>
				<CardBody>
					<CardText>
						Use the following interface to send out notifications to the users. You may input a URL or
						select a module / page / video in the app. Input a body as well for the message to display
						to the users. Keep in mind messages too long will be shortened by the phone, so make sure to
						to put the most important stuff first.
					</CardText>

					<FormGroup>
						<Label>Deep Link Type</Label>
						<br/>
						<ButtonGroup>
							<Button
								color="primary"
								onClick={selectDeepLinkType(DeepLinkType.NO_DEEP_LINK)}
								disabled={deepLinkType === DeepLinkType.NO_DEEP_LINK}
							>
								No Deep Link
							</Button>
							<Button
								color="primary"
								onClick={selectDeepLinkType(DeepLinkType.URL)}
								disabled={deepLinkType === DeepLinkType.URL}
							>
								Custom URL
							</Button>
							<Button
								color="primary"
								onClick={selectDeepLinkType(DeepLinkType.APP_LOCATION)}
								disabled={deepLinkType === DeepLinkType.APP_LOCATION}
							>
								App Location
							</Button>
							<Button
								color="primary"
								onClick={selectDeepLinkType(DeepLinkType.PODCASTS)}
								disabled={deepLinkType === DeepLinkType.PODCASTS}
							>
								Podcasts
							</Button>
						</ButtonGroup>
					</FormGroup>

					{
						deepLinkType === DeepLinkType.URL &&
                        <React.Fragment>
                            <FormGroup>
                                <Label>Deep Link URL</Label>
                                <Input type="url" onChange={createOnChange(setCustomURL)}/>
                                <FormText color="muted">
                                    Please double check your url has "http://" or "https://", some phones will not open
                                    these urls in their webviews without it.
                                </FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label>Header Title</Label>
                                <Input type="url" onChange={createOnChange(setCustomURLHeader)}/>
                                <FormText color="muted">
                                    The title of the page within the app.
                                </FormText>
                            </FormGroup>
                        </React.Fragment>
					}

					{
						deepLinkType === DeepLinkType.APP_LOCATION &&
                        <FormGroup>
                            <Label>Location</Label>
                            <Select
                                value={selectedLocation}
                                onChange={setSelectedLocation}
                                options={options}
                                searchable={true}
                            />
                            <FormText color="muted">
                                Choosing an app will take you to the screen with all the sub modules.
                                <br/>
                                Choosing a submodule will take you to the page with all the videos for your selection.
                                <br/>
                                Choosing a video will open the video.
                            </FormText>
                        </FormGroup>
					}

					{
						deepLinkType === DeepLinkType.PODCASTS &&
                        <FormGroup>
                            <Label>Pocasts</Label>
                            <FormText color="muted">
                                This notification will open the podcasts page in the app
                            </FormText>
                        </FormGroup>
					}

					<FormGroup>
						<Label>Title</Label>
						<Input type="text" onChange={createOnChange(setTitle)}/>
					</FormGroup>

					<FormGroup>
						<Label>Body</Label>
						<Input type="textarea" onChange={createOnChange(setBody)}/>
					</FormGroup>

					<Button className="mt-4 mb-2" color="success" onClick={sendNotification} disabled={sending}>
						Send Notifications
					</Button>

					{/*<Button onClick={test} className="mt-5 ml-5">*/}
					{/*	test*/}
					{/*</Button>*/}


					{
						deepLinkType !== DeepLinkType.NO_DEEP_LINK &&
                        <FormText>
                            I highly suggest you test your deep link before sending the notifications. You can by using
                            a
                            device with Grapplearts installed and pressing on the following link or copying it into a
                            browser.<br/>
                            <a href={deepLink} target="_blank">{deepLink}</a>
                        </FormText>
					}

				</CardBody>
			</Card>
		</Container>
	)
};

async function createOptions(): Promise<IOption[]> {
	const options: IOption[] = [];
	let obj;
	try {
		obj = await new DefaultApi().getLegacyDatabaseGet({});
	} catch (e) {
		const error: APIError = await e.json();
		alert(error);
		return;
	}
	for (const app of obj.apps.reverse()) {
		options.push({value: app, label: "App: " + app.title});
		if ((app as IGAContainerSubmoduleApp).techniques) {
			for (const technique of (app as IGAContainerSubmoduleApp).techniques) {
				if (!(technique as IGAContainerHeader).sectionTitle) {
					options.push({
						parent: app,
						value: technique as IGAContainerTechnique,
						label: `Video (${app.title}): ` + (technique as IGAContainerTechnique).description
					});
				}
			}
		}
		if ((app as IGAContainerApp).apps) {
			for (const submoduleApp of (app as IGAContainerApp).apps) {
				options.push({value: submoduleApp, label: `Submodule App (${app.title}): ` + submoduleApp.title});
				for (const technique of submoduleApp.techniques) {
					if (!(technique as IGAContainerHeader).sectionTitle) {
						options.push({
							parent: submoduleApp,
							value: technique as IGAContainerTechnique,
							label: `Video (${submoduleApp.title}): ` + (technique as IGAContainerTechnique).description
						});
					}
				}
			}
		}
	}
	return options;
}

export default NotificationManager;

import React, {ChangeEventHandler, useState} from "react";
import {Button, Card, CardBody, CardHeader, Container, Input, Label} from "reactstrap";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {AdminApi, APIError} from "client";
import {addError, login} from "../redux/meta/MetaActions";
import {useHistory} from "react-router-dom";

interface ILoginForm {
	user: string;
	password: string;
}

interface ILoginProps {
	token?: any;
	dispatch?: any;
}

const Login: React.FC<ILoginProps> = (props: ILoginProps) => {

	const {token, dispatch} = props;
	const history = useHistory();
	const [form, setForm] = useState<ILoginForm>();

	function createOnChange(key: keyof ILoginForm): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setForm({
				...form,
				[key]: e.target.value,
			});
		}
	}

	async function attemptLogin(): Promise<void> {
		let res;
		try {
			res = await new AdminApi().adminFormsLoginPost({
				adminLoginBody: {
					userName: form.user,
					password: form.password,
				},
			});
		} catch(e) {
			const error: APIError = await e.json();
			props.dispatch(addError(error.messages.join("\n")));
			return;
		}

		props.dispatch(login(res));
		history.push("/home");
	}

	return (
		<Container className="my-5 d-flex justify-content-center">
			<Card className="w-100" style={{maxWidth: 300}}>
				<CardHeader>
					Login
				</CardHeader>

				<CardBody>
					<div className="mb-4">
						<Label>Username</Label>
						<Input type="text" id="username" name="username" onChange={createOnChange("user")}/>
					</div>

					<div className="mb-4">
						<Label>Password</Label>
						<Input type="password" id="password" name="password" onChange={createOnChange("password")}/>
					</div>

					<div className="d-flex justify-content-end">
						<Button color="primary" onClick={attemptLogin}>
							Log in
						</Button>
					</div>
				</CardBody>
			</Card>
		</Container>
	);
};

export default connect((store: IStore, props: ILoginProps) => {
	return {
		token: store.metaStore.token,
	}
})(Login);

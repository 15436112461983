import React, {useEffect, useState} from "react";
import {addError, decrementLoading, incrementLoading, updateImageList} from "../redux/meta/MetaActions";
import {AdminApi, APIError, DefaultApi, IGAContainerApp, ModulesContainer} from "client";
import {IStore} from "../redux/defaultStore";
import {connect} from "react-redux";
import {Button, Card, CardBody, CardHeader, CardText, Container, Modal, Label, Input} from "reactstrap";
import SelectOptions, {ISelectOptions} from "../Components/SelectOptions";
import {IGAContainerJSON} from "client";
import {getConfig} from "../services/clientApis";
import {CopyToClipboard} from 'react-copy-to-clipboard'

interface IProps {
	token?: any;
	dispatch?: any;
}

const LegacyPromoCodeGenerator: React.FC<IProps> = (props) => {

	const [modulesContainer, setModulesContainer] = useState<IGAContainerJSON>();
	const [appID, setAppID] = useState<number>();
	const [nestedAppID, setNestedAppID] = useState<number>();
	const [linkType, setLinkType] = useState<string>("ios");
	const [expiration, setExpiration] = useState<string>("1 day");
	const [freeToken, setFreeToken] = useState<string>();
	const [deepLinkToken, setDeepLinkToken] = useState<string>();

	useEffect(() => {
		getLegacyDatabase().then().catch();
	}, [])

	async function getLegacyDatabase() {
		props.dispatch(incrementLoading());
		let res;
		try {
			res = await new DefaultApi().getLegacyDatabaseGet({});
		} catch (e) {
			const error: APIError = await e.json();
			props.dispatch(addError(error.messages.join("\n")));
			return;
		}
		props.dispatch(decrementLoading());

		setModulesContainer(res);
	}

	function createOnChange(setFunction: (v: any) => void): any {
		return (e) => {
			setFunction(e.target.value);
		}
	}

	async function submit() {
		props.dispatch(incrementLoading());
		try {
			const {freeToken, deepLinkToken} = await new AdminApi(getConfig(props.token)).getLegacyPromoCodePost({
				getLegacyPromoCodeBody: {
					appID,
					nestedAppID,
					linkType,
					expiration
				}
			});
			setFreeToken(freeToken);
			setDeepLinkToken(deepLinkToken);
		} catch (e) {
			const error: APIError = await e.json();
			props.dispatch(addError(error.messages.join("\n")));
			return;
		}
		props.dispatch(decrementLoading());
	}

	let appIDs: ISelectOptions[];
	let nestedAppIDs: ISelectOptions[];

	if (modulesContainer) {
		appIDs = modulesContainer.apps.map((app, i) => {
			return {
				value: i,
				text: app.title,
			}
		})
	}

	if (modulesContainer && appID !== undefined && modulesContainer.apps[appID].hasOwnProperty("apps")) {
		nestedAppIDs = (modulesContainer.apps[appID] as IGAContainerApp).apps.map((app, i) => {
			return {
				value: i,
				text: app.title
			}
		})
	} else if (nestedAppID !== undefined) {
		setNestedAppID(undefined);
	}

	return (
		<Container className="my-5">
			<Card>
				<CardHeader>
					Promo Code Generator
				</CardHeader>
				<CardBody>
					<CardText>
						This is a port of the code generator to use up to date data from the database, instead of the
						hardcoded version.
						This will only work for people using the newest version of the application, and have recently
						opened their
						application. It will not work if a module has been updated since the last time the user had
						opened their
						application with a stable internet connection.
					</CardText>
					{
						modulesContainer &&
                        <React.Fragment>
                          <Label>App</Label>
                          <Input type="select" value={appID} onChange={createOnChange(setAppID)}>
                            <option value={-1} selected>Select an App</option>
                            <hr/>
                            <SelectOptions fullOps={appIDs}/>
                          </Input>
                          <Label className="mt-3">Nested App</Label>
                          <Input disabled={!nestedAppIDs} type="select" value={nestedAppID}
                                 onChange={createOnChange(setNestedAppID)}>
                            <option value={-1} selected>Select a Nested App</option>
                            <hr/>
							  {
								  nestedAppIDs &&
                                  <SelectOptions fullOps={nestedAppIDs}/>
							  }
                          </Input>
                          <Label className="mt-3">Link Type</Label>
                          <Input type="select" value={linkType} onChange={createOnChange(setLinkType)}>
                            <SelectOptions
                              fullOps={[
								  {value: "ios", text: "iOS"},
								  {value: "android", text: "Android"}
							  ]}
                            />
                          </Input>
                          <Label className="mt-3">Expiration Limit</Label>
                          <Input type="select" value={expiration} onChange={createOnChange(setExpiration)}>
                            <SelectOptions
                              fullOps={[
								  {value: "1 day", text: "1 Day"},
								  {value: "1 week", text: "1 Week"},
								  {value: "1 month", text: "1 Month"},
								  {value: "1 year", text: "1 Year"},
								  {value: "100 years", text: "100 Years"},
							  ]}
                            />
                          </Input>

                          <Button
                            disabled={appID === undefined || linkType === undefined || expiration === undefined}
                            className="mt-5"
                            color="primary"
                            onClick={submit}
                          >
                            Submit
                          </Button>
                        </React.Fragment>
					}
					{
						(freeToken && deepLinkToken) &&
                        <Card className="mt-5">
                          <Card className="mb-4">
                            <CardHeader componentClass="h3">Free App Code
                              <CopyToClipboard text={freeToken}>
                                <Button className="ml-3" size="sm">
                                  Copy
                                </Button>
                              </CopyToClipboard>
                            </CardHeader>
                            <CardBody>
                              <CardText>{freeToken}</CardText>
                            </CardBody>
                          </Card>

                          <Card>
                            <CardHeader componentClass="h3">Deep Link
                              <CopyToClipboard text={deepLinkToken}>
                                <Button className="ml-3" size="sm">
                                  Copy
                                </Button>
                              </CopyToClipboard>
                            </CardHeader>
                            <CardBody>
                              <CardText>{deepLinkToken}</CardText>
                            </CardBody>
                          </Card>
                        </Card>
					}
				</CardBody>
			</Card>
		</Container>
	);
}

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
	}
})(LegacyPromoCodeGenerator);
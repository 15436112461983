import {Asset, Token} from "client";

export interface IMetaStore {
	token?: Token;
	loadingCount: number;
	errors: string[];
	imageList: Array<Asset>;
}

export default {
	loadingCount: 0,
	errors: [],
	imageList: [],
} as IMetaStore;

import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

interface IProps {
	module: string;
	isOpen: boolean;

	onCancel(): void;
	onDelete?(): void;
}

const DeleteModulePromptModal: React.FC<IProps> = (props: IProps) => {

	const {module, isOpen} = props;

	return (
		<Modal
			fade={true}
			centered={true}
			isOpen={isOpen}
			onClose={props.onCancel}
			scrollable={true}
		>
			{isOpen && (
				<React.Fragment>
					<ModalHeader toggle={props.onCancel}>{`Delete ${module}?`}</ModalHeader>
					<ModalBody>
						<h3 className="mb-3">
							{`Really Remove the "${module}" module?`}
						</h3>
						<p>
							After confirming here, you will still have to click the "Save All Changes" button at the
							bottom of the page. If you want to cancel your deletion, refresh or leave the page without saving.
						</p>

						<ModalFooter>
							<div className="d-flex justify-content-between w-100">
								<Button color="link" onClick={props.onCancel}>
									No, Don't Delete
								</Button>

								<Button color="danger" onClick={props.onDelete}>
									Yes, Delete
								</Button>
							</div>
						</ModalFooter>
					</ModalBody>
				</React.Fragment>
			)}
		</Modal>
	);
};

export default DeleteModulePromptModal;

import React, {ReactNode, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Button, Card, CardBody, CardHeader, Container, Modal} from "reactstrap";
import SelectModuleOrNew from "../Components/SelectModuleOrNew";
import {CreateNewModuleForm, defaultModule} from "../Components/CreateNewModuleForm";
import {AdminApi, APIError, DefaultApi, Module, ModulesContainer} from "client";
import {addError, decrementLoading, incrementLoading, updateImageList} from "../redux/meta/MetaActions";
import {getConfig} from "../services/clientApis";
import ReOrderModuleListItem from "../Components/ReOrderModuleListItem";
import cloneDeep from "lodash.clonedeep";
import remove from "lodash.remove";

const defaultModuleContainer: ModulesContainer = {
	creationDate: 0,
	description: "",
	rootModule: [],
};

interface IHomeProps {
	token?: any;
	dispatch?: any;
}

const Home: React.FC<IHomeProps> = (props: IHomeProps) => {

	const {token} = props;
	const [form, setForm] = useState<Module>();
	const [modulesContainer, setModulesContainer] = useState<ModulesContainer>(defaultModuleContainer);
	const [selectedExisting, setSelectedExisting] = useState(-1);
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [showReOrderModules, setShowReOrderModules] = useState(false);
	const [keyTracker, setKeyTracker] = useState(1);

	useEffect(() => {
		getModuleContainer().then().catch();
		getImageList().then().catch();
	}, []);

	function toggleShowSuccessModal(): void {
		setShowSuccessModal(!showSuccessModal);
	}

	/**
	 * Get list of images/assets and save to redux
	 *
	 */
	async function getImageList(): Promise<void> {
		props.dispatch(incrementLoading());
		let res;
		try {
			res = await new AdminApi(getConfig(token)).adminGetAssetListGet();
			props.dispatch(decrementLoading());
		} catch (e) {
			const error: APIError = await e.json();
			props.dispatch(decrementLoading());
			props.dispatch(addError(error.messages.join("\n")));
			return;
		}

		props.dispatch(updateImageList(res));
	}

	/**
	 * Get the module container data
	 *
	 */
	async function getModuleContainer(): Promise<void> {
		props.dispatch(incrementLoading());
		let res;
		try {
			res = await new DefaultApi().getModulesContainerGet();
			props.dispatch(decrementLoading());
		} catch (e) {
			const error: APIError = await e.json();
			props.dispatch(addError(error.messages.join("\n")));
			props.dispatch(decrementLoading());
			return;
		}

		setModulesContainer(res);
	}

	/**
	 * onChange helper for the form.
	 *
	 * I have used "v" instead of "e.target.value", as I've set up the
	 * actual form to only pass us the "value", accounting for certain
	 * inputs being different (such as nested arrays of sub modules / entries)
	 * - Spencer
	 *
	 * @param key
	 * @param v
	 */
	function createOnChange(key: keyof Module, v: any): void {
		setForm({
			...form,
			[key]: v,
		})
	}

	/**
	 * Call & save module container with new data
	 *
	 */
	async function attemptSaveContainer(): Promise<void> {
		props.dispatch(incrementLoading());

		let newRootModule: Array<Module> = modulesContainer.rootModule;

		if (selectedExisting < 0) {
			newRootModule = modulesContainer.rootModule.concat(form);
			newRootModule = remove(newRootModule, undefined);

		} else {
			newRootModule[selectedExisting] = form;
		}

		// remove all empty string fields
		for (let i = 0; i < newRootModule.length; i++) {
			for (let k = 0; k < newRootModule.entries.length; k++) {
				for (const key of Object.keys(newRootModule[i].entries[k])) {
					if (typeof newRootModule[i].entries[k][key] === "string" && newRootModule[i].entries[k][key].length < 1) {
						delete newRootModule[i].entries[k][key];
					}
				}

			}

			for (let j = 0; j < newRootModule[i].subModules.length; j++) {
				for (let k = 0; k < newRootModule.entries.length; k++) {
					for (const key of Object.keys(newRootModule[i].entries[k])) {
						if (typeof newRootModule[i].entries[k][key] === "string" && newRootModule[i].entries[k][key].length < 1) {
							delete newRootModule[i].entries[k][key];
						}
					}
				}
			}
		}

		try {
			 await new AdminApi(getConfig(token)).adminFormsCreateNewModuleContainerPost({
				modulesContainer: {
					rootModule: newRootModule,
				},
			});

			await getModuleContainer();

			toggleShowSuccessModal();
			setSelectedExisting(-1);
			setForm(null);
			props.dispatch(decrementLoading());
		} catch (e) {
			const error: APIError = await e.json();
			props.dispatch(decrementLoading());
			props.dispatch(addError(error.messages.join("\n")));
			return;
		}
	}

	/**
	 * Easy work around for issue where changing which module was selected
	 * would keep displaying the previous state's version of form values,
	 * so setForm(null) causes the form to be de-rendered, then 1 second later update it again,
	 * forcing it to appear with no worry about the cached vlaue issue
	 *
	 * @param index
	 */
	function handleSelectExistingModule(index: number): void {
		setSelectedExisting(index);
		if (index > -1) {
			setForm(null);
			props.dispatch(incrementLoading());
			setTimeout(() => {
				setForm(cloneDeep(modulesContainer.rootModule[index]));
				props.dispatch(decrementLoading());
			}, 1000);
		} else {
			setForm(null);
		}
	}

	function onCreateNew(): void {
		setForm(defaultModule);
		setSelectedExisting(-1);
	}

	/**
	 * function for re-ordering modules
	 *
	 * @param up
	 * @param i
	 */
	function reOrderModule(up: boolean, i: number): void {
		const mods: Array<Module> = cloneDeep(modulesContainer.rootModule);
		if (
			(mods.length < 2) ||
			(up && i === 0) ||
			(!up && i >= (mods.length - 1))
		) {
			return;
		}

		const a: Module = cloneDeep(mods[i]);
		const swapIndex: number = up ? (i - 1) : (i + 1);
		const b: Module = cloneDeep(mods[swapIndex]);

		mods[i] = b;
		mods[swapIndex] = a;
		modulesContainer.rootModule = mods;
		setModulesContainer(modulesContainer);

		if (selectedExisting == i) {
			setSelectedExisting(swapIndex);
		} else if ((selectedExisting > -1) && selectedExisting == swapIndex) {
			setSelectedExisting(i);
		}

		setKeyTracker(keyTracker + 1);
	}

	function onDeleteModule(i: number): void {
		const mods: Array<Module> = cloneDeep(modulesContainer.rootModule);
		mods.splice(i, 1);
		modulesContainer.rootModule = mods;
		setModulesContainer(modulesContainer);
		if (selectedExisting == i) {
			setSelectedExisting(-1);
			setForm(null);
		} else if (i < selectedExisting) {
			setSelectedExisting(selectedExisting - 1);
		}
		setKeyTracker(keyTracker + 1);
		props.dispatch(decrementLoading());
	}

	function toggleShowModuleReOrdering(e): void {
		e.preventDefault();
		setShowReOrderModules(!showReOrderModules);
	}

	function createModulesToReorder(modules: Array<Module> = []): ReactNode {
		return modules.map((module: Module, i: number) => {

			function reOrderHelper(up: boolean): void {
				reOrderModule(up, i);
			}

			function onDeleteHelper(): void {
				onDeleteModule(i);
			}

			return (
				<ReOrderModuleListItem
					title={module.title}
					selectedModule={i == selectedExisting}
					index={i}
					onReOrder={reOrderHelper}
					onDelete={onDeleteHelper}
				/>
			);
		});
	}

	return (
		<Container className="my-5">
			<Modal
				isOpen={showSuccessModal}
				fade={true}
				centered={true}
				contentClassName={"p-3"}
			>
				<h4>
					Module Added/Updated Successfully!
				</h4>

				<div>
					<p>
						Your changes have been saved. To continue making edits to the module that was just saved,
						please select it from the drop-down menu.
					</p>
				</div>

				<div className="d-flex justify-content-end">
					<Button color="success" onClick={toggleShowSuccessModal}>
						Done
					</Button>
				</div>
			</Modal>

			<SelectModuleOrNew
				modules={modulesContainer.rootModule}
				onSelectExisting={handleSelectExistingModule}
				selectedExisting={selectedExisting}
				onClickCreateNew={onCreateNew}
			/>

			{modulesContainer && (
				<Card className="w-100 my-5" key={keyTracker + "-re-order"}>
					<CardHeader className="d-flex justify-content-between">
						<span>
							Re-order or delete Modules
						</span>

						<a href="#" onClick={toggleShowModuleReOrdering}>
							{showReOrderModules ? "Hide" : "Show"}
						</a>
					</CardHeader>

					{showReOrderModules && (
						<CardBody>
							<div>
								<p>
									To change the order of a new module, first save it, then it will appear in this list to be re-ordered.
								</p>
							</div>

							{createModulesToReorder(modulesContainer.rootModule)}
						</CardBody>
					)}
				</Card>
			)}

			{form && (
				<CreateNewModuleForm
					key={keyTracker}
					values={form}
					isSubModule={false}
					onChange={createOnChange}
					onSave={attemptSaveContainer}
				/>
			)}

			<div className="d-flex justify-content-center my-4">
				<Button color="link" onClick={attemptSaveContainer}>
					<span style={{fontSize: "1.25rem"}}>
						Save All Changes
					</span>
				</Button>
			</div>
		</Container>
	);
};

export default connect((store: IStore, props: IHomeProps) => {
	return {
		token: store.metaStore.token,
	}
})(Home);

import React from "react";
import {Button, Modal} from "reactstrap";

interface IConfirmRemovalModalProps {
	removalType: string;
	show: boolean;
	closeModal(): void;
	confirmRemoval(): void;
}

const ConfirmRemovalModal: React.FC<IConfirmRemovalModalProps> = (props: IConfirmRemovalModalProps) => {

	const {removalType, show, closeModal, confirmRemoval} = props;

	return (
		<Modal
			fade={true}
			centered={true}
			isOpen={show}
			onClose={closeModal}
			scrollable={true}
			contentClassName="p-3"
		>
			<h3>
				{`Really Remove this ${removalType}?`}
			</h3>

			<div className="d-flex justify-content-between mt-4">
				<Button color="link" onClick={closeModal}>
					No, Don't Remove
				</Button>

				<Button color="danger" onClick={confirmRemoval}>
					Yes, Remove
				</Button>
			</div>
		</Modal>
	);
};

export default ConfirmRemovalModal;
